<template>
  <div class="manage-account">
    <div class="container fluid">
      <div class="card p-3">
        <h2 class="mainTitle mb-5">Manage Account</h2>
        <div class="main-contents">
          <fd-form @submit="submitForm" class="row mb-5">
            <!-- Company Detail -->
            <fd-form-section title="Company & Role" class="px-1">
              <div class="col-12 d-flex align-items-center mb-2">
                <span class="company-name mr-1">
                  {{ accountData.developer.name }}
                </span>
                <chip class="bg-accent2 fg-white">
                  {{ accountData.rolePermission.name }}
                </chip>
              </div>
            </fd-form-section>
            <!-- Personal Information -->
            <fd-form-section title="Account Details" class="px-1">
              <image-uploader
                v-model="accountData.avatar"
                class="col-12 px-1 mb-2"
                :multiple="false"
                label="Avatar"
                @error="
                  (error, imageName) => {
                    $reportError(error, '[Developer CP] Upload Avatar');
                  }
                "
              >
              </image-uploader>
              <fd-input
                v-model="accountData.name"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Name"
                name="mstaffName"
                type="text"
                :validators="[validator.required]"
              >
              </fd-input>
              <fd-input
                v-model="accountData.email"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Email"
                name="mstaffEmail"
                type="email"
                disabled
                :validators="[validator.required, validator.email]"
              >
              </fd-input>
              <tel-input
                ref="tel"
                v-model="phoneInput"
                :tel.sync="accountData.phoneNo"
                class="col-12 px-1 mb-2 d-block"
                label="Contact No."
                required
              >
              </tel-input>
            </fd-form-section>

            <div class="col-12 text-right">
              <button class="btn main">Update Profile</button>
            </div>
          </fd-form>

          <!-- Change Password -->
          <div class="col-12">
            <change-password
              ref="changePassword"
              @update="updatePassword"
            ></change-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  phone,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import { account as accountAPI } from "@/api";
import AccountModel from "@/models/account";

import TelInput from "@/modules/User/components/TelInput";
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    const submitHandlerOptions = {
      role: "developer user"
    };
    const { handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleSubmitError
    };
  },
  components: {
    TelInput,
    Chip: () => import("@/components/GlobalComponents/Chip"),
    ChangePassword: () => import("@/components/ManageAccount/ChangePassword"),
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      phoneInput: "",
      accountData: {
        name: "",
        email: "",
        phoneNo: {},
        rolePermission: {
          name: ""
        },
        developer: {}
      },
      validator: {
        required: required,
        min: minLength,
        email: email,
        password: password,
        phone: phone
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);
      await this.getAccountData();
      this.$store.commit("setIsLoading", false);
    },
    // ============================== API Related ==============================
    async getAccountData() {
      try {
        let data = await accountAPI.getProfile();
        this.accountData = AccountModel.getToEditResponse(data);
        this.$refs.tel.setTel(this.accountData);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch profile data. Please try again later."
        });
        throw error;
      }
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    async updatePassword(password) {
      this.$store.commit("setIsLoading", true);
      try {
        await accountAPI.changePassword({ password: password });

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Password updated successfully."
        });

        this.$refs.changePassword.clearInputs();

        this.$store.commit("setIsLoading", false);
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `An error occured. ${error.response.data.message}`
        });
        throw error;
      }
    },
    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);
        let response = await accountAPI.editProfile(
          AccountModel.postPayload(this.accountData)
        );
        this.$store.commit("setIsLoading", false);

        this.$auth.user(response);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Account has been updated successfully."
        });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    }
  }
};
</script>

<style lang="scss">
.manage-account {
  .company-name {
    font-size: 18px;
  }
}
</style>
